<template>
    <div style="position:relative;"  class="crane-and-rigging">
        <div class="container landing-banner-container" style="padding-top: 7.5rem">
            <div class="left-banner">
                <h1 style="font-size: 3.625rem;font-weight: bold;line-height: 1.21;color: #021222;padding-bottom: 1.875rem;">Empowering Geotechnical Project Management Through <span style="color:#00c8d8;"></span>Geotechnical Engineer App</h1>
                <div>
                    <p>Managing geotechnical projects involves handling complex geotechnical data, tight schedules, and multiple stakeholders. To ensure smooth and efficient project execution, the geotechnical engineer needs a reliable geotechnical engineer app. In this regard, Record TIME is the perfect solution, enabling geotechnical engineers to enhance collaboration, increase productivity, and deliver outstanding results. 

                        <br><br>Additionally, effective communication among team members, clients, and other stakeholders is an important part of geotechnical projects. With Record TIME's geotechnical engineer app, users can perform smooth document sharing and real time updates, which ensures automated workflow, seamless communication, and fosters teamwork.
                        <br><br>In Record TIME, a centralised platform performs all project-related tasks, such as: scheduling tasks, timelines, and deadlines. This automated workflow and centralised approach allow geotechnical engineers to access, modify, and monitor project performance in real time, regardless of their location.
                    </p>
                </div>
                <br><br>
                <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                    @click="redirectToLink('https://recordtime.com.au/jobsite-management-software')" class="mr-7 btn-1" elevation="0">
                    Read More
                </v-btn>
                <v-btn height="56" class="get-demo-btn"  color="white" @click="getDemo()">
                        Get A Demo Now
                    </v-btn><br/><br/>
            </div>
            <div  class="right-banner" style="text-align: right;">
                <img :src="`${publicPath}assets/whoweserve/geotechnical-engineer-software.webp`" alt="engineer using geotechnical engineer app to manage their project operation"  style="max-width: 90%;">
            </div>
        <div style="clear: both;"></div>

        </div>

        <v-container>
            <div class="gray-container">  
                     <div class="left-blog1">
                         <br>
                         <h3>Empowering Informed Decision-Making with Detailed Plant and Equipment Information</h3>
                         <p style="text-align:left;">Record TIME facilitates the meticulous listing of all <a href="https://recordtime.com.au/plant-and-equipment-management-software">plants and equipment</a>, along with important details such as specifications, maintenance history, and availability. Geotechnical engineers can easily access this information, helping them make informed decisions and prevent resource conflicts. 
    <br><br>In addition, the software allows easy scheduling, enabling you to allocate manpower and equipment precisely when and where they are needed. By optimising resource allocation, projects can stay on track, reducing unnecessary delays and costs.
                         </p>
                
                     </div>
                     <div class="right-blog1">
                        <br><br>
                        <img :src="`${publicPath}assets/whoweserve/geotechnical-engineer-software-record-time.webp`" alt="effective geotechnical projects management">   
                     </div>
                     <div style="clear:both;"></div>
            </div>
                 </v-container>
     
        <v-container>

                    
                    <h2 style="font-size:42px; text-align:center;">Simplify Geotechnical Engineering Inspections</h2><br>
                    <p>Maintaining accurate inspection records is critical in geotechnical engineering. Record TIME simplifies the process by allowing you to record inspections digitally and access them in real time. You can create all kinds of inspection and record forms using Record TIME’s docket book. Likewise, you can also share these inspection reports with team members and stakeholders seamlessly.
                        <br><br>Furthermore,  Record TIME allows you to create comprehensive <a href="https://recordtime.com.au/blog/plant-pre-start-checklist-template">pre-start checklists</a>, ensuring that all safety measures are diligently followed before commencing work; significantly reducing the risk of accidents and enhancing overall project safety.
                    </p>
                    <br> 
                  <center><img :src="`${publicPath}assets/whoweserve/why-record-time-for-geotechnical-engineering.webp`" alt="advantage of using record time for geotechnical engineering project" style="width: 100%;"></center>


                <br>
                         <h2>Are you a geotechnical engineer looking for a powerful and efficient tool to streamline your project management processes in the field? Look no further! Contact us today!!</h2>
            
                  
                        <br>
                        <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                    @click="redirectToLink('https://recordtime.com.au/pricing')" class="mr-7 btn-1" elevation="0">
                    View Pricing
                </v-btn>
                
        </v-container>


       
        <get-demo-modal v-if="getDemoModal" :demoEmail="demoEmail" @excEmit="excEmit" @getDemoModalClose="getDemoModalClose" @demoResponse="demoResponse" url="https://www.youtube.com/embed/62kV7CIU_F4"/>

    </div>

</template>
<style lang="scss">
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
        }
    }
    .left-banner{
        width: 45%; 
        float: left;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .right-banner{
        width: 55%; 
        float: right;
        padding: 0px 20px 20px;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
            h1{ font-size: 40px !important;}
        }
    }
    .heavy-civil{
        .banner-img{
            @media only screen and (max-width: 600px) {
            position: relative !important;
            }

            @media only screen and (max-width: 1280px) {
                width: 100% !important;
                top: 20px !important;
                height: auto !important;
            }
        }
        p{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 0px !important;
            }
        }
    }
    .banner-img{
        @media only screen and (max-width: 600px) {
            position: relative !important;
        }

        @media only screen and (max-width: 1280px) {
            position: relative !important;
        }
    }
    .gray-container{
        background: #F8F9FB;
        padding: 20px;
        margin-top: 30px;
        h3 {
            font-size: 32px;
            font-weight: bold;
            text-align: left;
            color: $black-pearl;
            margin-bottom: 15px;

            span {
            color: $faux-dark-turquoise;
            }
        }
        p{
            margin-top: 40px;
            text-align: left;
            color: #000;
        }
        .list{
            h1{
                color: #00C8D8;
            }
            p{
                color: #000;
                text-align: left;
            }
        }
    }
    .left-blog1{
      float:left;
      max-width: calc(100% - 37.5rem);
      padding-right: 15px;
      @media only screen and (max-width: 1280px) {
        max-width: 100%;
      }
    }   
    .right-blog1{
      width: 600px;
      float:right;
      @media only screen and (max-width: 1280px) {
        width: 100%;
      }
      img{
        max-width: 600px;
        width: 100%;
        @media only screen and (max-width: 1280px) {
            max-width: 100%;
        }
      }
    }
    @media only screen and (max-width: 600px) {
      .left-blog1{
        float: none;
        max-width: 100%;
        padding-right: 0px;
      }
      .right-blog1{
        float: none;
        max-width: 100%;
      }
    }
</style>
<script>

import GetDemoModal from '@/components/GetDemoModal.vue';

export default{
    metaInfo: {
    title: 'Geotechnical Engineer App for Project Management Processes',
    // titleTemplate: 'Record TIME → %s',
    meta: [
      {name: 'name', content: ''},
      {name: 'description', content: 'Geotechnical engineer app to streamline project management task. It simplifies collaboration and optimises resource allocation in real time.'},
      {name: 'keyword', content: 'geotechnical engineer app'}
      ],
    link: [
      { rel: 'canonical', href: 'https://recordtime.com.au/geotechnical-engineer-app' }
    ]
  },
    components: {
        GetDemoModal
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            getDemoModal: false,
        }
    },
    methods: {
        redirectToLink(url) {
        window.open(url, '_blank');
        },

        getDemoModalClose(){
            this.getDemoModal = false;
            this.demoEmail = '';
        },
        getDemo(){
            this.getDemoModal = true;
        }
    }
}
</script>
